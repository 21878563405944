import React from "react";

// Composant fonctionnel Pre qui reçoit des props
function Pre(props) {
  // Retourne un élément div dont l'ID dépend de la prop 'load'.
  // Si 'load' est true, l'ID sera 'preloader' (peut être utilisé pour afficher un préchargeur).
  // Si 'load' est false, l'ID sera 'preloader-none' (peut être utilisé pour masquer le préchargeur).
  return <div id={props.load ? "preloader" : "preloader-none"}></div>;
}

// Exporte le composant Pre pour être utilisé dans d'autres fichiers
export default Pre;
