import {useState} from 'react'

export const useInitial = () => {
    const [status, setStatus] = useState({
        loading: false,
        error: false
    })
    /*
      Implémente la fonctionnalité de hook ici.
      Si vous devez exécuter une opération asynchrone, définissez le chargement sur true et une fois terminé, définissez le chargement sur false.
      Si vous avez détecté des erreurs, définissez le statut d'erreur sur true.
      Le hook initial est considéré comme terminé avec succès s'il renvoie {loading : false, error : false}.
    */
    return status
}
