import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ExperienceCard from "./experinceCard";
import Pre from "../Pre";
import Footer from "../Footer";  // Preloader

function Experience() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);


    const experiences = [

        {
            title: "Administrateur Blog Personnel",
            companyName: "",
            duration: "Avril 2024 - Aujourd'hui",
            city: " Remote - France",
            companyLogo: "https://upload.wikimedia.org/wikipedia/commons/2/20/WordPress_logo.svg",
            tasks: [
                "Installation et configuration complète de WordPress pour la gestion du blog personnel, incluant la gestion des bases de données et des fichiers.",
                "Personnalisation de l’apparence du blog à l'aide de thèmes et de modifications CSS pour garantir une expérience utilisateur optimale.",
                "Gestion des plugins pour ajouter des fonctionnalités comme le SEO, la sécurité et les performances du site.",
                "Optimisation du contenu pour le référencement naturel (SEO), en utilisant des outils comme Yoast SEO et en intégrant des mots-clés stratégiques.",
                "Sécurisation du blog à travers des mesures telles que l'installation de plugins de sécurité et la mise en place de sauvegardes automatiques.",

            ],
            demoLink: "https://blogcyber.level.ovh",
        },


        {
            title: "Job Étudiant",
            companyName: "KFC",
            duration: "juin 2022 - Ajourdhui",
            city: "Ollioules - France",
            companyLogo: "https://upload.wikimedia.org/wikipedia/commons/b/b2/KFC_logo_%28Straight%29.png",
            tasks: [
                "Communication efficace pour coordonner les différentes étapes de préparation et de livraison des commandes",
                "Accueil des clients, réponse à leurs questions concernant les produits, et gestion des réclamations",
                "Formation aux procédures de sécurité incendie et manipulation sécurisée des équipements de cuisson.",
                "Organisation des postes de travail en cuisine pour optimiser les flux de travail et réduire les temps d’attente",
            ],
        },

        {
            title: "HACKVENS | Capture de Flag",
            companyName: "ADVENS",
            duration: " Octobre 2023 , Bug Bounty & CTF",
            city: "Lyon - France",
            companyLogo: "https://media.advens.com/wp-content/themes/advens-blog/assets/img/advens-logo-black.svg",
            tasks: [
                "Participation active au programmes de Bug Bounty et des défis Capture The Flag (CTF) où l’une des épreuves les plus marquantes a été la réalisation de scénarios d’attaque sur des infrastructures industrielles, où j'ai découvert et exploité des vulnérabilités dans des automates industriels et des systèmes de supervision",
                "J'ai découvert l’importance de revoir les configurations par défaut de Kubernetes, souvent insuffisantes pour garantir une sécurité optimale.",
                "J'ai également eu l'occasion de travailler avec des outils plus spécialisés pour les objets connectés (IoT), en particulier pour l'analyse des logiciels embarqués et des protocoles de communication.",
                "Travaillant au sein d’une équipe, j'ai pu échanger des idées et des stratégies pour résoudre les défis du CTF plus efficacement. Ce travail d’équipe a permis de combiner ndes compétences en programmation, analyse de vulnérabilités, et exploitation de failles.",
            ],
            demoLink: "https://hackvens.fr/2023/",
        },
        {
            title: "Stagiaire informatique",
            companyName: "Global SPES",
            duration: "Juin 2021 - Sept 2021",
            city: "Douala - Cameroun",
            companyLogo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRtLn39lVgMKmYlzz9ZMrwnyNSoszF-PYnEA&s",
            tasks: [
                " Installation et mise à jour du système d'exploitation (Windows) et des applications professionnelles",
                "Participation à la configuration des routeurs, switches et serveurs pour la mise en place de réseaux locaux dans des entreprises clientes",
                "Assistance aux utilisateurs pour la résolution des problèmes informatiques et la configuration de leurs périphériques",
            ],
        },

        {
            title: "Stagiaire informatique",
            companyName: "Global SPES",
            duration: "Juin 2020 - Aout 2020",
            city: "Douala - Cameroun",
            companyLogo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRtLn39lVgMKmYlzz9ZMrwnyNSoszF-PYnEA&s",
            tasks: [
                "Participation à la préparation et à l'animation de formations sur les logiciels de la suite Microsoft Office (Word, Excel, PowerPoint, Publisher) pour les utilisateurs débutants et intermédiaires..",
                "Résolution des problèmes réseau (connexion, partages de fichiers, accès distant) et assistance aux utilisateurs pour la configuration de leurs périphériques réseau",
                "Assistance et conseils pour l'utilisation optimale des technologies.",
            ],
        },

    ];

    return (
        <Container fluid className="project-section">
            <Pre load={loading} /> {/* Preloader Component */}
            {!loading && (
                <>
                    <Particle />
                    <Container>
                        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                            <Col
                                md={12}
                                className="project-card"
                                style={{ paddingBottom: "50px" }}
                            >
                                <h1  className="project-heading" >
                                    <strong className="purple">Expériences</strong>
                                </h1>
                            </Col>
                        </Row>

                        {/* Taille de la grille 3x4 */}
                        <Row>
                            {experiences.map((exp, index) => (
                                <Col md={4} key={index} className="project-card" >
                                    <ExperienceCard
                                        title={exp.title}
                                        companyName={exp.companyName}
                                        duration={exp.duration}
                                        city={exp.city}
                                        companyLogo={exp.companyLogo}
                                        tasks={exp.tasks}
                                        demoLink={exp.demoLink}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </>
            )}
        </Container>
    );
}

export default Experience;
