import React from "react";
import Card from "react-bootstrap/Card";
import {ImCheckboxChecked} from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Salut à tous, je m'appelle <span className="purple">Level sony </span> et je viens de <a href="https://trackip.level-sony.fr" target="_blank" rel="noopener noreferrer"> <span className="purple">127.0.0.1</span> </a>
            <br/>
            Je suis actuellement <span className="purple">étudiant en Master Cybersecurité</span> à l'<span className="purple"> ISEN YNCREA Mediterrannée</span> .
            <br />
            Je compléte un Master en Cybersecurité. J'ai également une passion la Programmation web.
            <br />
            <br />
           En dehors du codage , d'autres activités que j'adore faire !
          </p>
          <ul>
            <li className="about-activity">
              <ImCheckboxChecked/> Participation aux CTFs
            </li>
            <li className="about-activity">
              <ImCheckboxChecked/> Rédaction de blogs techniques
            </li>

            <li className="about-activity">
              <ImCheckboxChecked/> Apprendre l'Anglais
            </li>
            <li className="about-activity">
              <ImCheckboxChecked/> Voyages
            </li>
          </ul>

          <p style={{color: "rgb(155 126 172)"}}>
            "Efforcez-vous de construire des choses qui font la différence !"{" "}
          </p>
          <footer className="blockquote-footer">Sony-level</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}
export default AboutCard;
