import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import favicon from "../../Assets/favicon_.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import about from "../About/About";
import {Link} from "react-router-dom";
import {SiBetfair, SiMaildotru} from "react-icons/si";
import {SiBlogger} from "react-icons/si";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LAISSEZ- MOI ME <span className="purple"> PRESENTER </span>
            </h1>
              <p className="home-about-body">
                  <i>
                      <b className="purple">Depuis   </b>
                  </i> que j'ai commencé à explorer le monde du numérique, j'ai développé une passion profonde
                  pour la <i>
                  <b className="purple"> cybersécurité et le développement web </b>
              </i> . Mon <b className="purple"> objectif</b> est de comprendre en
                  profondeur le fonctionnement des systèmes et de contibuer à la mise en place
                  des moyens efficaces pour les sécuriser contre les menaces émergentes. 💻🔒 🤷‍♂️
                  <br/>

                  <br/>
                  En complément de mes études, je m'&nbsp;
                  <i>
                      <b className="purple">intéresse </b> également à des domaines connexes tels que l'<b className="purple">
                      intelligence artificielle & le deploiement de solutions sécurisées
                  </b>. J'explore activement comment ces technologies peuvent être exploitées pour détecter et prévenir les cyberattaques,
                      me permettant de commprendre comment créer des environnements numériques sécurisés. {" "}

                  </i>
                  <br/>
                  <br/>
                  {/*  Dans la mesure du possible,  J'envisage de développer un .
                  avec<b className="purple">blog personnel</b>

                  <i>
                      <b className="purple">
                          {" "}
                          Modern Javascript Library and Frameworks
                      </b>
                  </i>
                  &nbsp; like
                  <i>
                      <b className="purple"> React.js and Next.js</b>
                  </i> */}

                  En savoir plus sur moi, <Link to="/about" element={ <about/>}> <b className="purple">
                  {" "}
                  cliquez ici.
              </b></Link>

              </p>
          </Col>
            <Col md={4} className="myAvtar">
                <Tilt>
                <img src={favicon} className="img-fluid" alt="avatar_" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>TROUVEZ-MOI SUR</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
              <ul className="home-about-social-links">
                  <li className="social-icons">
                      <a
                          href="mailto:sony-dilane-level.mbango@isen.yncrea.fr"
                          className="icon-colour home-social-icons"
                      >
                          <SiMaildotru/>
                      </a>
                  </li>
                  <li className="social-icons">
                      <a
                          href="https://github.com/sony-level"
                          target="_blank"
                          rel="noreferrer"
                          className="icon-colour  home-social-icons"
                      >
                          <AiFillGithub/>
                      </a>
                  </li>
                  <li className="social-icons">
                      <a
                          href="https://twitter.com/sony_level"
                          target="_blank"
                          rel="noreferrer"
                          className="icon-colour  home-social-icons"
                      >
                          <AiOutlineTwitter/>
                      </a>
                  </li>
                  <li className="social-icons">
                      <a
                          href="https://www.linkedin.com/in/level-sony/"
                          target="_blank"
                          rel="noreferrer"
                          className="icon-colour  home-social-icons"
                      >
                          <FaLinkedinIn/>
                      </a>
                  </li>
                  <li className="social-icons">
                      <a
                          href="https://blogcyber.level.ovh/"
                          target="_blank"
                          rel="noreferrer"
                          className="icon-colour home-social-icons"
                      >
                          <SiBlogger/>
                      </a>
                  </li>
              </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
