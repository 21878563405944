import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Etudiant en cybersécurité",
          "Apprenti Développeur Web en autodidacte",
          "Curieux de nouvelles technologies",
          "Recherche un stage de 04 mois en cybersécurité & programmation",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 15,
      }}
    />
  );
}

export default Type;
