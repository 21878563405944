import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/realtor.jpg";
import track from "../../Assets/Projects/track.png";
import dockerpng from "../../Assets/Projects/docker.png";
import quick from "../../Assets/Projects/Quick Mail.png";
import dockdock from "../../Assets/Projects/image.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
         Mon récent <strong className="purple">travail </strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dockerpng}
              isBlog={false}
              title="Docker Manager Dashboard "
              description="Une interface graphique intuitive qui simplifie la gestion des conteneurs Docker en permettant de démarrer, arrêter, redémarrer, supprimer des conteneurs, et visualiser leurs journaux, le tout sans utiliser le terminal."
              ghLink="https://github.com/sony-level/DockerManager"
              //demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dockdock}
              isBlog={false}
              title="Dockdock-Go"
              description="Devellopement de l'interface web d'harbor pour l'ajout de scanner de vulnerabilité plus adapté et la gestion des images docker. "
              ghLink="https://github.com/Master1-Project"
              demoLink="http://57.129.14.109:8081/swagger-ui/#/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={track}
              isBlog={false}
              title="Track IP"
              description="Une petite application Web qui permet aux utilisateurs de localiser l’emplacement géographique d’une adresse IP donnée. L’application fournit des informations telles que l’adresse IP, l’emplacement (y compris la ville, la région et le pays)"
              ghLink="https://github.com/sony-level/Track-IP"
              demoLink="https://trackip.level-sony.fr/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Challenge learning OWASP"
              description="Projet visant à fournir une ressource complète pour comprendre et mitiger les principales vulnérabilités de sécurité Web répertoriées dans le top 10 de l'OWASP. Le projet est divisé en 10 parties, chacune couvrant une vulnérabilité spécifique."
              ghLink="https://github.com/sony-level/OWASP-10"
              //demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={quick}
              isBlog={false}
              title="Quick Mail"
              description="Quick Mail est un service de messagerie temporaire qui permet de créer une adresse email aléatoire et de recevoir des messages."
              ghLink="https://github.com/sony-level/QuickMail"
              demoLink="https://quickmail.level-sony.fr/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={}
              isBlog={false}
              title="F"
              description=""
              ghLink=""
              // demoLink=""
            />
          </Col>*/}

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
