import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    SiVisualstudiocode,
    SiPostman,
    SiSlack,
    SiVercel,
    SiMacos, SiLinux, SiJetbrains, SiGit, SiGrafana, SiProxmox, SiJenkins, SiDocker, SiKubernetes, SiKeycdn,
} from "react-icons/si";
import {DiBlackberry, HiArchive} from "react-icons/hi";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={3} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={3} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
        <Col xs={3} md={2} className="tech-icons">
            <SiJetbrains />
        </Col>
        <Col xs={3} md={2} className="tech-icons">
            <SiDocker />
        </Col>
        <Col xs={3} md={2} className="tech-icons">
            <SiKubernetes/>
        </Col>
      <Col xs={3} md={2} className="tech-icons">
        <SiGrafana/>
      </Col>
      <Col xs={3} md={2} className="tech-icons">
        <SiGit/>
      </Col>
      <Col xs={3} md={2} className="tech-icons">
        <SiVercel/>
      </Col>
        <Col xs={3} md={2} className="tech-icons">
            <SiProxmox/>
        </Col>
        <Col xs={3} md={2} className="tech-icons">
            <SiJenkins/>
        </Col>
    </Row>
  );
}

export default Toolstack;
