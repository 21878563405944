import React from "react";
//import {DiNodejsSmall, DiTerminal, SiResearchgate} from "react-icons/di";
import { Row , Col  } from "react-bootstrap";
import {SiKalilinux, SiOwasp, SiWireguard, SiWireshark} from "react-icons/si";



function Cyberstack() {
    return (
        <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={3} md={2} className="tech-icons">
                <SiOwasp/>
            </Col>
            <Col xs={3} md={2} className="tech-icons">
                <SiKalilinux/>
            </Col>
            <Col xs={3} md={2} className="tech-icons">
                <SiWireshark/>
            </Col>
            <Col xs={3} md={2} className="tech-icons">
                <SiWireguard/>
            </Col>


        </Row>

);
}

export default Cyberstack;