import React from "react";
//import { Container, Row, Col, Nav } from "react-bootstrap";
//import { AiOutlineUser } from "react-icons/ai";
//import { Link } from "react-router-dom";
//import Particle from "../Particle";
import Card from "react-bootstrap/Card";
import {Image} from "react-bootstrap";
import {CgWebsite} from "react-icons/cg";
import Button from "react-bootstrap/Button";


function ExperienceCard({ title, duration, city, companyName, companyLogo, tasks  , demoLink}) {

    return (
        <Card className="project-card-view">

            <Card.Img variant="top" src={companyLogo} alt="Company Logo"/>
            <br/>
            <Card.Body>
                <Card.Title>
                    <b className="purple">{title}</b>
                </Card.Title>
                <br/>
                <Card.Text style={{textAlign: "justify"}}>
                    <strong>Entreprise:</strong> {companyName} <br/>
                    <strong>Période:</strong> {duration} <br/>
                    <strong>Ville:</strong> {city}
                </Card.Text>
                <Card.Text style={{textAlign: "justify"}}>
                    <ul>
                        {tasks.map((task, index) => (
                            <li key={index}>{task}</li>
                        ))}
                    </ul>
                </Card.Text>
                {demoLink && (
                    <Button
                        variant="primary"
                        href={demoLink}
                        target="_blank"
                        style={{marginLeft: "10px"}}
                    >
                        <CgWebsite/> &nbsp; lien
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
}

export default ExperienceCard;